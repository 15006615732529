import React from 'react';
import styled from 'styled-components';
import { Layout } from '../components';
import { useUpdateHeight } from '../hooks/useUpdateHeight';
// import debounce from 'lodash/debounce';

const CenteredVideo = styled.div`
  width: 100%;
  height: ${(props) => (props.vidHeight ? props.vidHeight + 'px' : '100%')};
`;

const EmbedWrap = styled.div`
  height: 100%;
  width: 100%;
`;
const PostItemContainer = styled.div`
  position: relative;
  z-index: 100;
  transition: ${(props) => props.theme.transitions.boom.transition};
  height: 100%;
  width: 100%;
  padding: 3rem 8.75rem;
  @media (max-width: 1170px) {
    padding: 3rem 5.75rem;
  }
  @media (max-width: 750px) {
    padding: 3rem 1.75rem;
  }
`;

const Index = () => {
  const vidRef = React.useRef(null);
  const vidHeight = useUpdateHeight(vidRef, 0.4167424931756142);

  return (
    <Layout>
      <PostItemContainer>
        <CenteredVideo ref={vidRef} vidHeight={vidHeight}>
          <EmbedWrap>
            <iframe
              id="demo-reel"
              src="https://player.vimeo.com/video/371505845?title=0&byline=0&portrait=0"
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                marginBottom: 0,
              }}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </EmbedWrap>
          <script src="https://player.vimeo.com/api/player.js" />
        </CenteredVideo>
      </PostItemContainer>
    </Layout>
  );
};

export default Index;
