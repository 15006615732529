import { useState, useEffect } from 'react';

export function useUpdateHeight(vidRef, multiple) {
  const [vidHeight, setVidHeight] = useState(Number);

  useEffect(() => {
    let timeoutId = null;
    const calcHeight = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(
        () => setVidHeight(vidRef.current.offsetWidth * multiple),
        150
      );
    };
    calcHeight();
    window.addEventListener('resize', calcHeight);
    return () => window.removeEventListener('resize', calcHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return vidHeight;
}
